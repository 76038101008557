<template>
  <div class="h-screen flex w-full bg-img">
    <div
      class="vx-col w-4/5 sm:w-4/5 md:w-3/5 lg:w-3/4 xl:w-3/5 mx-auto self-center"
    >
      <vx-card>
        <div slot="no-body" class="full-page-bg-color">
          <div class="vx-row">
            <div
              class="vx-col hidden sm:hidden md:hidden lg:block lg:w-1/2 mx-auto self-center"
            >
              <img
                src="@/assets/images/logo/Cyberbank konecta logo.svg"
                alt="login"
                class="mx-auto"
                width="60%"
              />
            </div>
            <div
              class="vx-col sm:w-full md:w-full lg:w-1/2 mx-auto self-center bg-white"
            >
              <div class="p-8">
                <div class="vx-card__title mb-6">
                  <h4 class="mb-6">
                    {{ lang.chargifySignup.title[languageSelected] }}
                  </h4>
                  <p class="mb-2">{{ lang.chargifySignup.subscriptionNumber[languageSelected] }} <b>#{{ this.$route.query.id }}</b></p>
                  <p v-dompurify-html="message"></p>
                </div>

                <vs-button
                  type="border"
                  to="/pages/login"
                  class="px-4 w-full md:w-auto"
                >
                  {{ lang.chargifySignup.backToLogin[languageSelected] }}
                </vs-button>
              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import konecta from '@/services/konecta'

export default {
  name: 'ChargifySignup',
  data() {
    return {
      message: ''
    }
  },
  computed: {
    ...mapState(['lang', 'languageSelected', 'session']),
  },
  methods: {
    chargifySignup() {
      konecta
        .post(`/company/signup/chargify/${this.$route.query.id}`, {})
        .then(response => {
          if (response && response.data && response.data.email) {
            this.message = this.lang.chargifySignup.description[this.languageSelected]
              .replace('<EMAIL>', response.data.email)
              .replace('<ID>', this.$route.query.id)
          }
        })
        .catch(err => {
          console.error(err)
          this.$vs.notify({
            title: 'Oops!',
            text: this.lang.chargifySignup.error.generic[
              this.languageSelected
            ],
            color: 'danger'
          })
        })
    }
  },
  mounted() {
    this.message = this.lang.chargifySignup.wait[this.languageSelected]
    this.$vs.loading.close('body > .con-vs-loading')
    if (!this.$route.query.id) {
      return this.$router.push('/pages/404')
    }
    this.chargifySignup()
  }
}
</script>
